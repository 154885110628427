// 一、配置axios
import axios from 'axios'
import store from '@/store/index' //如果使用vuex，那么token，userinfo都可以在登录以后存储到store中，不需要使用storage
import qs from 'qs'
import router from '@/router/index'
import {Dialog,Toast} from "vant";
// 获取浏览器的接口地址。
let baseUrl = window.location.origin
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
const instance = axios.create({
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
    },
})
// axios.defaults.timeout = 50000
// axios.defaults.withCredentials = true
// 请求拦截器，设置token
instance.interceptors.request.use(config => {
    // console.log('token-->' + store.getters.get_token)
    // config.headers.token = 'ab5e3ff4373f407d8614346126af6b51';
    if (store.getters.get_token) {
        config.headers.token = store.getters.get_token;
    }
    // console.log(router.currentRoute.value.path)
    config.cancelToken = source.token
    if (
        config.data &&
        config.headers['Content-Type'] === 'application/x-www-form-urlencoded;charset=UTF-8'
    ) {
        config.data = qs.stringify(config.data)
    }
    return config
}, error => {
    // 可以安装elementui等ui组件，将错误信息输出到界面。
    console.log(error)
    return Promise.error(error)
})
// 响应拦截器
instance.interceptors.response.use(response => {
    if (response.status === 200) {
        // console.log(router)
        // console.log(window.location.href)
        // return Promise.resolve(response)
        if (response.data.code === '00004'){
            // console.log('登录过期'+router.currentRoute.value.fullPath)
            // store.commit('clearUserInfo')  // 使用vuex存储过用户信息，这里需要清空一下。
            source.cancel();
            store.commit('del_token')
            store.commit('setBackUrl', window.location.href)
            router.push({
                path: '/login'
            })
        }else if (response.data.code === '00015') {
            Toast.clear()
            //内容违规
            Dialog.alert({
                message: '请重新输入要发的内容，平台拒绝发送任何有关涉黄、涉政、辱骂、赌博、吸毒、恐怖信息以及网站链接',
                confirmButtonColor: '#00D477'

            })

        } else if (response.data.code === '00012'){
            Toast.clear()
            Toast("请勿重复提交")
        }else {
            return Promise.resolve(response)
        }
    } else {
        return Promise.reject(response)
    }
},
    error => {
        if (axios.isCancel(error)) { // 取消请求的情况下，终端Promise调用链
            return new Promise(() => {});
        } else {
            return Promise.reject(error);
        }
    })

export default instance